import {XEnum} from "../model/user/x-enum.entity";

export enum Param {
    // n - noclaharen
    n_zmluvyPlatneOd = "n_zmluvyPlatneOd",
    n_exspiraciaVriec = "n_exspiraciaVriec",
    n_maxCisloVreca = "n_maxCisloVreca",
    n_novyKlientPredplateneNoci = "n_novyKlientPredplateneNoci",
    n_denPredlzenyOxHodin = "n_denPredlzenyOxHodin"
}

export enum EnumEnum {
    rola = "rola",
    pohlavie = "pohlavie",
    zdravotnaPoistovna = "zdravotnaPoistovna",
    statusVSluzbe = "statusVSluzbe",
    rodinnyStav = "rodinnyStav",
    socialnePostavenie = "socialnePostavenie",
    odkazanostNaSocialnuSluzbu = "odkazanostNaSocialnuSluzbu",
    stupenOdkazanosti = "stupenOdkazanosti",
    druhPrijmu = "druhPrijmu",
    vzdelanie = "vzdelanie",
    mestskaCast = "mestskaCast",
    typCinnosti = "typCinnosti",
    cinnostTypZaznamu = "cinnostTypZaznamu",
    palivoVIgnise = "palivoVIgnise",
    druhStravy = "druhStravy",
    sposobUkoncenia = "sposobUkoncenia",
    noclaharenPlatba = "noclaharenPlatba",
    uzivatelSluzby = "uzivatelSluzby",
    doklady = "doklady",
    typPrav = "typPrav",
    xGroupByType = "xGroupByType",
    xsStatFieldType = "xsStatFieldType",
    xsRowAccessType = "xsRowAccessType",
    xAggregateFunction = "xAggregateFunction",
    samostatnost = "samostatnost",
    podielajuceSaOsoby = "podielajuceSaOsoby",
    xFieldType = "xFieldType",
    xrWhereItemType = "xrWhereItemType",
    xOrderType = "xOrderType",
    lujzaPoradProjekt = "lujzaPoradProjekt",
    vincentPoradProjekt = "vincentPoradProjekt",
    streetworkTim = "streetworkTim"
}

export enum StatusVSluzbe {
    aktivnyKlient = "aktivnyKlient",
    neaktivnyKlient = "neaktivnyKlient",
    vZss = "vZss", // enabled = false
    nieJeKlient = "nieJeKlient",
    zosnuly = "zosnuly"
}

export enum CinnostTypZaznamu {
    generovany = "generovany",
    manualneVytvoreny = "manualneVytvoreny"
}

export enum TypCinnosti {
    sprchovanie = "sprchovanie",
    vymenaPostelnejBielizne = "vymenaPostelnejBielizne",
    pranieOsobnychVeci = "pranieOsobnychVeci",
    ubytovanie = "ubytovanie"
}

// kedze typescript nepodporuje enum typu object literal (len number alebo string), pouzivame tuto konstrukciu
// typ NoclaharenPlatbaEnumType je potrebny aby NoclaharenPlatbaEnum poskytovala presne moznosti Zaplatil, Nezaplatil, Predplatene
interface NoclaharenPlatbaEnumType {
    Zaplatil: XEnum;
    Nezaplatil: XEnum;
    Predplatene: XEnum;
}

// cast "as XEnum" pouzivame aby sme nemuseli zadavat atributy enabled, readOnly, enumOrder, xEnumEnum
export const NoclaharenPlatbaEnum: NoclaharenPlatbaEnumType = {
    Zaplatil: {id: 96, code: 'zaplatil', name: 'Zaplatil'} as XEnum,
    Nezaplatil: {id: 97, code: 'nezaplatil', name: 'Nezaplatil'} as XEnum,
    Predplatene: {id: 98, code: 'predplatene', name: 'Predplatené'} as XEnum
} as const

export enum TypPrav {
    lenCheckbox = "lenCheckbox",
    citanieZapis = "citanieZapis"
}

export enum SluzbaRolaPravaKod {
    zapisPanelSpecPorad = "zapisPanelSpecPorad",
    zapisPanelPsychPorad = "zapisPanelPsychPorad",
    statistikyPouzivanie = "statistikyPouzivanie",
    statistikyLenSpustanie = "statistikyLenSpustanie"
}

// zatial tieto pouzivame v kode
export enum Rola {
    veduci = "veduci",
    pracovnik = "pracovnik",
    socialnyPracovnik = "socialnyPracovnik",
    specPoradPracovnik = "specPoradPracovnik",
    psychPoradPracovnik = "psychPoradPracovnik"
}

// pouzivame nazov SluzbaEnum aby sme odlisili od entity Sluzba
export enum SluzbaEnum {
    lujzaPoradenstvo = "lujzaPoradenstvo",
    noclaharen = "noclaharen",
    alzbetaPoradenstvo = "alzbetaPoradenstvo", // AlzbetaNDC
    streetwork = "streetwork",
    IPB = "IPB",
    komunitneOrganizovanie = "komunitneOrganizovanie",
    komunitneCentrumZahorskaVes = "komunitneCentrumZahorskaVes",
    vincentPoradenstvo = "vincentPoradenstvo",
    vincentPrepravnaSluzba = "vincentPrepravnaSluzba",
    alzbetaOsetrovna = "alzbetaOsetrovna",
    lujzaPrepravnasluzba = "lujzaPrepravnasluzba",
    IPB_sofer = "IPB_sofer",
    specializovanePoradenstvo = "specializovanePoradenstvo"
}

export enum TypPorad {
    specPorad = "specPorad",
    psychPorad = "psychPorad"
}

// zodpoveda XEnum xGroupByType
// TODO - do lib-ky
export enum XGroupByType {
    entityEnum = "entityEnum",
    xEnum = "xEnum",
    day = "day",
    month = "month",
    year = "year",
    age = "age",
    intervalSet = "intervalSet",
    value = "value"
}

// zodpoveda XEnum xsStatFieldType
// TODO - do lib-ky
export enum XsStatFieldType {
    rowCount = "rowCount",
    aggregateField = "aggregateField",
    fieldSetRowCount = "fieldSetRowCount",
    fieldSetFieldCount = "fieldSetFieldCount",
    fieldSetAggregateField = "fieldSetAggregateField"
}

// zodpoveda XEnum xsRowAccessType
// TODO - do lib-ky
export enum XsRowAccessType {
    onlyAdmin = "onlyAdmin",
    allUsers = "allUsers",
    specificUsers = "specificUsers"
}

// zodpoveda XEnum xrWhereItemType
// TODO - do lib-ky
export enum XrWhereItemType {
    groupByField = "groupByField",
    dateFromTo = "dateFromTo",
    filter = "filter"
}

// zodpoveda XEnum xOrderType
// TODO - do lib-ky
export enum XOrderType {
    asc = "asc",
    desc = "desc"
}

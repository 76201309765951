import React from "react";
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";

// ako trojstavovy XCheckbox, ale realizovany dvomi checkboxami
export const XBooleanYesNoBase = (props: {
    value: boolean | null;
    onChange: (value: boolean | null) => void;
    readOnly?: boolean;
}) => {

    const onChangeCheckboxYes = (e: CheckboxChangeEvent) => {

        let value: boolean | null;
        if (e.checked) {
            value = true;
        }
        else {
            // checkbox was unchecked
            value = null;
        }
        props.onChange(value);
    }

    const onChangeCheckboxNo = (e: CheckboxChangeEvent) => {

        let value: boolean | null;
        if (e.checked) {
            value = false;
        }
        else {
            // checkbox was unchecked
            value = null;
        }
        props.onChange(value);
    }

    return (
        <div className="flex" style={{marginBottom: '0.5rem'}}>
            <div className="mr-1">{xLocaleOption('yes')}</div><Checkbox key='checkbox-yes' checked={props.value === true} onChange={onChangeCheckboxYes} disabled={props.readOnly} className="mr-2"/>
            <div className="mr-1">{xLocaleOption('no')}</div><Checkbox key='checkbox-no' checked={props.value === false} onChange={onChangeCheckboxNo} disabled={props.readOnly}/>
        </div>
    );
}

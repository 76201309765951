import React from "react";
import {SelectButton, SelectButtonChangeEvent} from "primereact/selectbutton";

// custom switch to change the layout of browse
// not used direct in the lib, can be integrated into XLazyDataTable through property headerElementRight

export type XExpansionStateType = "expanded" | "colapsed";

interface Option {
    icon: string;
    value: XExpansionStateType;
}

export const XExpansionSwitch = (props: {
    value: XExpansionStateType;
    onChange: (value: XExpansionStateType) => void;
    className?: string;
}) => {

    const options: Option[] = [
        {icon: 'pi pi-chevron-right', value: 'colapsed'},
        {icon: 'pi pi-chevron-down', value: 'expanded'}
    ];

    const itemTemplate = (option: Option) => {
        return <i className={option.icon}></i>;
    }

    return (
        <SelectButton value={props.value} onChange={(e: SelectButtonChangeEvent) => props.onChange(e.value)}
                      options={options} optionValue="value" itemTemplate={itemTemplate} allowEmpty={false} className={props.className}/>
    );
}

import React, {Component} from "react";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {IconType} from "primereact/utils";
import {Button, ButtonProps} from "primereact/button";
import {XUtilsMetadataCommon} from "@michalrakus/x-react-web-lib/XUtilsMetadataCommon";
import {XAssoc, XEntity} from "@michalrakus/x-react-web-lib/lib/serverApi/XEntityMetadata";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";

// XFormPanelList funguje nad oneToMany asociaciami, ak chceme child zaznam zobrazit nie v tabulke ale v paneli (fieldy maju custom layout)
// panel obsahuje button na vymazanie child zaznamu (typicky vpravo hore), pod poslednym panelom (t.j. pod poslednym child zaznamom) je zas button na pridanie dalsieho child zaznamu
// komponent by mal podla moznosti fungovat obdobne ako XFormDataTable2 (na zaklade neho bol tento komponent vytvoreny)

export interface XFormPanelListProps {
    form: XFormBase;
    assocField: string;
    // ak chceme mat jednoduchsie api v XInput*DT componente, treba do panelBody posielat "this" na tento component a z neho tahat entity + form
    // zatial posielame entity
    panelBody: (entity: string, row: any, removeButton: JSX.Element) => JSX.Element;
    dataKey?: string;
    sortField: string;
    label?: string;
    readOnly?: boolean;
    showAddRemoveButtons: boolean;
    onClickAddRow?: () => void;
    onClickRemoveRow?: (row: any) => void;
    addRowLabel?: string;
    addRowIcon: IconType<ButtonProps>;
    removeRowLabel?: string;
    removeRowIcon: IconType<ButtonProps>;
    confirmRemoveRow: boolean;
}

export class XFormPanelList extends Component<XFormPanelListProps> {

    public static defaultProps = {
        sortField: "idFieldOnUpdate",
        showAddRemoveButtons: true,
        addRowIcon: "pi pi-plus",
        removeRowIcon: "pi pi-times",
        confirmRemoveRow: true
    };

    entity: string;
    dataKey?: string;

    constructor(props: XFormPanelListProps) {
        super(props);
        this.dataKey = props.dataKey;
        const xEntityForm: XEntity = XUtilsMetadataCommon.getXEntity(props.form.getEntity());
        const xAssocToMany: XAssoc = XUtilsMetadataCommon.getXAssocToMany(xEntityForm, props.assocField);
        this.entity = xAssocToMany.entityName;
        if (this.dataKey === undefined) {
            this.dataKey = XUtilsMetadataCommon.getXEntity(this.entity).idField;
        }
        this.onClickAddRow = this.onClickAddRow.bind(this);
        this.removeRow = this.removeRow.bind(this);

        //props.form.addXFormDataTable(this);

        //props.form.addField(props.assocField + '.*FAKE*'); - vzdy mame aspon 1 field, nie je to potrebne
        // for (const child of props.children) {
        //     const childColumn = child as {props: XFormColumnBaseProps}; // nevedel som to krajsie...
        //     if (childColumn.props.type !== "custom") {
        //         const field = props.assocField + '.' + this.getPathForColumn(childColumn.props);
        //         props.form.addField(field);
        //     }
        // }
    }

    onClickAddRow(): void {
        if (this.props.onClickAddRow) {
            // custom add row
            this.props.onClickAddRow();
        }
        else {
            // default add row
            this.props.form.onTableAddRow(this.props.assocField, {}, this.dataKey);
        }
    };

    removeRow(row: any) {
        if (this.props.confirmRemoveRow) {
            if (!window.confirm(xLocaleOption('formRemoveRowConfirm'))) {
                return;
            }
        }

        if (this.props.onClickRemoveRow) {
            // custom remove
            this.props.onClickRemoveRow(row);
        }
        else {
            // default remove
            this.props.form.onTableRemoveRow(this.props.assocField, row);
        }
    }

    render() {
        const label = this.props.label ?? this.props.assocField;
        const readOnly = this.props.form.formReadOnlyBase(this.props.assocField) || (this.props.readOnly ?? false);

        const object: XObject | null = this.props.form.state.object;
        const rowList: any[] = object !== null ? object[this.props.assocField] : [];

        let addRowLabel: string | undefined = undefined;
        let removeRowLabel: string | undefined = undefined;
        if (this.props.showAddRemoveButtons) {
            // calling xLocaleOption does not work in standard default values initialisation place (public static defaultProps)
            addRowLabel = this.props.addRowLabel ?? xLocaleOption('addRow');
            removeRowLabel = this.props.removeRowLabel ?? xLocaleOption('removeRow'); // zjavne sa pouziva len pri tabulke, ak mame velky remove button uplne dole vedla add button
        }

        let elemPanelList: JSX.Element[] = [];
        for (const row of rowList) {
            elemPanelList.push(this.props.panelBody(this.entity, row, <Button icon="pi pi-times" onClick={() => this.removeRow(row)} disabled={readOnly}/>));
        }

        return (
            <div>
                <div className="flex justify-content-center">
                    <div style={{fontSize: '120%'}}>{label}</div>
                </div>
                {elemPanelList}
                <div className="flex justify-content-center">
                    <XButton icon={this.props.addRowIcon} label={addRowLabel} onClick={this.onClickAddRow} disabled={readOnly}/>
                </div>
            </div>
        );
    }
}

import React from "react";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {ObecBrowse} from "../ciselniky/ObecBrowse";
import {ObecForm} from "../ciselniky/ObecForm";
import {StatBrowse} from "../ciselniky/StatBrowse";
import {StatForm} from "../ciselniky/StatForm";
import {Obec} from "../../model/ciselniky/obec.entity";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {statSlovensko} from "../../common/constants";

// komponent ktory zobrazi polozky adresy daneho klienta
export const KlientSluzbaAdresa = (props: {
    form: XFormBase; // this
    osetrovna: boolean; // osetrovna nezobrazuje/nepouziva ulica, cisloDomu, psc
    labelStyle?: React.CSSProperties;
    statObecWidth?: string; // pouzivane na mobile, zavisi od labelStyle, preto je robene cez tento parameter
}) => {

    // pomocna metodka
    const getKlientSluzba = (): KlientSluzba | null => {
        return props.form.state.object;
    }

    // helper
    const mameZahranicie = (klientSluzba: KlientSluzba): boolean => {
        // mame ciselnik obec zadavame, len ak je zadany nejaky stat a ten stat nie je Slovensko
        return (klientSluzba && klientSluzba.klient && klientSluzba.klient.stat && klientSluzba.klient.stat.id !== statSlovensko.id) as boolean;
    }

    const onChangeKlientStat = (e: XFieldChangeEvent<KlientSluzba>) => {
        // ak bol stat zmeneny na zahranicie a mame vyplneny ciselnik obec, tak prehodime obec do textoveho fieldu obecText - dalej nech si s tym user robi co uzna za vhodne (napr. vymaze)
        // dovod je ze nechceme mat nekonzistentny stav, kedy mame zahranicie a zaroven mame vyplnenu slovensku obec (v readOnly fielde obec)
        // opacny postup (presun obecText -> obec) nerealizujeme, lebo nevieme najst obec v ciselniku (mame aj kopec dat z migracie kde nie je mozne najst obec)
        if (mameZahranicie(e.object)) {
            if (e.object.klient.obec !== null) {
                // ak je obecText prazdne (co by malo byt vzdy), tak don zapiseme nazov obce
                if (e.object.klient.obecText === null || e.object.klient.obecText === '') {
                    e.object.klient.obecText = e.object.klient.obec.nazov;
                }
                // zrusime obec
                e.object.klient.obec = null;
            }
        }
    }

    const readOnlyKlientObecText = (klientSluzba: KlientSluzba): boolean => {
        // obecText pouzivame len pre zahranicie (a docasne po migracii)
        return !mameZahranicie(klientSluzba);
    }

    const onChangeKlientObecText = (e: XFieldChangeEvent<KlientSluzba>) => {
        // ak user zadal obec do textoveho fieldu obecText, tak zrusime ciselnikovu hodnotu (obec), ak nejaku mame
        if (e.object.klient.obecText !== null) {
            e.object.klient.obec = null;
        }
    }

    const readOnlyKlientObec = (klientSluzba: KlientSluzba): boolean => {
        // ciselnik obec zadavame, len ak nemame zahranicie
        return mameZahranicie(klientSluzba);
    }

    const itemTemplateKlientObec = (suggestion: Obec, index: number, createStringValue: boolean, defaultValue: (suggestion: Obec) => string): React.ReactNode => {
        let obec: string = suggestion.nazov;
        if (suggestion.okres) {
            obec += ", okres " + suggestion.okres.nazov;
        }
        return obec;
    }

    const onChangeKlientObec = (e: XFieldChangeEvent<KlientSluzba>) => {
        // ak user zadal obec z ciselnika (obec), tak zrusime hodnotu v textovom fielde (obecText), ak nejaku mame
        if (e.object.klient.obec !== null) {
            e.object.klient.obecText = null;
        }
    }

    const klientSluzba: KlientSluzba | null = getKlientSluzba();

    const statObecWidth: string = props.statObecWidth ?? "21rem"; // default 21rem sa pouziva pre desktop formulare

    return (<React.Fragment>
        {/* stat sme presunuli na zaciatok, lebo ak je vyplneny nejaky zahranicny stat (nie Slovensko), tak sa pouziva obecText, pre Slovensko sa pouziva ciselnik obci */}
        <XAutoComplete form={props.form} assocField="klient.stat" displayField="nazov" label="Štát" labelStyle={props.labelStyle} width={statObecWidth}
                       readOnly={false} onChange={onChangeKlientStat}
                       searchBrowse={<StatBrowse/>} assocForm={<StatForm/>}/>
        {/* mesto - nahradzame ciselnikom obci, docasne zobrazujeme ako readOnly (a v osetrovni nepouzivame vobec) */}
        {!props.osetrovna ?
            [<XInputText form={props.form} field="klient.ulica" label="Ulica" labelStyle={props.labelStyle} inputStyle={{width: '13rem'}} readOnly={false}/>,
            <XInputText form={props.form} field="klient.cisloDomu" label="Číslo domu" labelStyle={props.labelStyle} inputStyle={{width: '7rem'}} readOnly={false}/>,
            <XInputText form={props.form} field="klient.psc" label="PSČ" labelStyle={props.labelStyle} inputStyle={{width: '5rem'}} readOnly={false}/>]
            : null
        }
        {/* poznamka: pre usporu miesta (a jednoduchsi zivot uzivatela) zobrazujeme obecText + obec ako readOnly len ak maju zapisanu nejaku hodnotu */}
        {klientSluzba && (!readOnlyKlientObecText(klientSluzba) || klientSluzba.klient.obecText !== null) ?
            <XInputText form={props.form} field="klient.obecText" label="Mesto/obec" labelStyle={props.labelStyle} inputStyle={{width:statObecWidth}}
                    readOnly={readOnlyKlientObecText} onChange={onChangeKlientObecText}/> : null}
        {/* poznamka: koli tomu aby fungovalo vyhladavanie okresnych miest (napr. Trencin, Piestany) nedavame do displayField "okres.nazov" - potom by pri natypovani Trencin zobrazil vsetky obce Trencin, co nechceme */}
        {/* poznamka 2: aby sme vedeli najst zaznamy Bratislava, resp. Kosice, pouzivame sortField="nazov" - inac by sortoval podla id a koli lazyLoadMaxRows=10 by natiahol ako prve mestske casti a zaznam Bratislava by sa nedostal do vyberu */}
        {klientSluzba && (!readOnlyKlientObec(klientSluzba) || klientSluzba.klient.obec !== null) ?
            <XAutoComplete form={props.form} assocField="klient.obec" label="Mesto/obec" labelStyle={props.labelStyle} readOnly={readOnlyKlientObec}
                           displayField={["nazov"]} fields={["okres.nazov"]} sortField="nazov" itemTemplate={itemTemplateKlientObec}
                           suggestionsLoad="lazy" minLength={2} onChange={onChangeKlientObec}
                           width={statObecWidth} scrollHeight="50rem"
                           searchBrowse={<ObecBrowse/>} assocForm={<ObecForm/>}/> : null}
    </React.Fragment>);
}

import {
    XLazyColumn,
    XLazyDataTable,
    XMultilineRenderType
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {VykazStreetworkForm} from "./VykazStreetworkForm";
import {Utils} from "../../Utils";
import {EnumEnum} from "../../common/enums";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {VykazStreetworkHlavickaForm} from "./VykazStreetworkHlavickaForm";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XHtmlRenderer} from "@michalrakus/x-react-web-lib/XHtmlRenderer";
import {useXStateSession} from "@michalrakus/x-react-web-lib/useXStateSession";

export const VykazStreetworkBrowse = (props: {}) => {

    const [multilineSwitchValue, setMultilineSwitchValue] = useXStateSession<XMultilineRenderType>(`vykaz-streetwork-browse-multiline-switch-value`, "allLines"); // init value

    const showExpandedRowMobile = (vykazStreetwork: VykazStreetwork, switchValue: XMultilineRenderType): boolean => {
        return switchValue !== "singleLine" && vykazStreetwork.priebehSluzby !== null;
    };

    const rowExpansionTemplateMobile = (vykazStreetwork: VykazStreetwork) => {
        return (
            <XHtmlRenderer htmlValue={vykazStreetwork.priebehSluzby} renderType={multilineSwitchValue} fewLinesCount={2}/>
        );
    };

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkHlavickaForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkHlavickaForm id={selectedRow.id}/>);
    }

    // tieto 2 funkcie (velky formular aj so zapismi) tu mozno budu docasne

    const onPridatSoZapismi = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkForm/>);
    }

    const onUpravitSoZapismi = (selectedRow: VykazStreetwork) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        (props as any).openForm(<VykazStreetworkForm id={selectedRow.id}/>);
    }

    const renderDesktop = () => {

        return (
            <XLazyDataTable entity="VykazStreetwork" label="Denník"
                            sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                            multilineSwitch={true}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                            appButtons={[<XButton key="pridat-so-zapismi" label="Pridať aj so zápismi"
                                                  onClick={onPridatSoZapismi}/>]}
                            appButtonsForRow={[{
                                key: "upravit-so-zapismi",
                                label: "Upraviť aj so zápismi",
                                onClick: onUpravitSoZapismi
                            }]}
                            displayed={(props as any).displayed}>
                <XLazyColumn field="id" header="ID" width="5rem" columnViewStatus={Utils.isUserAdmin()}/>
                <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
                <XLazyColumn field="tim.name" header="Tím" width="6rem" dropdownInFilter={true}
                             dropdownFilter={Utils.enumFilter(EnumEnum.streetworkTim)}/>
                <XLazyColumn field="vykazXUserList.xUser.name" header="Pracovníci" width="15rem"/>
                <XLazyColumn field="priebehSluzby" header="Priebeh služby" width="60rem" contentType="html"/>
            </XLazyDataTable>
        );
    }

    const renderMobile = () => {

        // width="calc(100vw - 15.3rem)" nefunguje, XLazyDataTable alebo DataTable nam to meni na calc(100vw)
        let columnPracovniciWidth: number = window.screen.availWidth - XUtils.toPX0("15.5rem"); // 15.5rem - pokusne zistene

        return (
            <XLazyDataTable entity="VykazStreetwork" label="Denník"
                            sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                            showExpandedRow={showExpandedRowMobile} rowExpansionTemplate={rowExpansionTemplateMobile}
                            autoFilter={true} fullTextSearch={false} showFilterButtons={false}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                            displayed={(props as any).displayed}
                            multilineSwitch={true} multilineSwitchValue={[multilineSwitchValue, setMultilineSwitchValue]}>
                <XLazyColumn field="datum" header="Dátum"/>
                <XLazyColumn field="tim.name" header="Tím" width="6rem" dropdownInFilter={true}
                             dropdownFilter={Utils.enumFilter(EnumEnum.streetworkTim)}/>
                <XLazyColumn field="vykazXUserList.xUser.name" header="Pracovníci" width={`${columnPracovniciWidth}px`}/>
            </XLazyDataTable>
        );
    }

    if (!XUtils.isMobile()) {
        return renderDesktop();
    }
    else {
        return renderMobile();
    }
}

import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {UbytovanieForm} from "./UbytovanieForm";
import {Utils} from "../../Utils";
import {EnumEnum} from "../../common/enums";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {DataTableFilterMeta} from "primereact/datatable";
import {FilterMatchMode} from "primereact/api";
import {KlientSluzbaForm} from "../klient/KlientSluzbaForm";

export const UbytovanieBrowse = (props: XBrowseProps & {dnes?: boolean;}) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<UbytovanieForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<UbytovanieForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> => {
        // vymazat dovolime len vtedy ked dovolujeme editaciu - veduci moze vzdy,
        // pracovnik alebo socialny pracovnik len ak mame dnesny datum (pozri UbytovanieForm.formReadOnly)
        if (!UbytovanieForm.mozeEditovatVymazat(selectedRow)) {
            alert("Vymazať je možné len záznam s dnešným dátumom, staršie záznamy môže vymazať len užívateľ s rolou Vedúci (alebo Admin).");
            return false;
        }

        if (window.confirm(xLocaleOption('removeRowConfirm'))) {
            await XUtils.post('remove-ubytovanie', {id: selectedRow.id});
            return true;
        }
        return false;
    }

    const filters: DataTableFilterMeta = {};
    if (props.dnes) {
        const today: Date = Utils.todayNoclaharen();
        filters["datum"] = {value: [today, today], matchMode: FilterMatchMode.BETWEEN};
    }

    const [fieldList, minLength]: [string[], number] = Utils.klientSluzbaFieldListForAutoComplete();

    return (
        <XLazyDataTable entity="Ubytovanie" stateKey={`ubytovanie-browse${props.dnes ? '-dnes' : ''}`}
                        label="Ubytovania" filters={filters} sortField="id desc" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow}
                        displayed={props.displayed}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="klientSluzba.cisloVreca" header="Číslo vreca" width="7rem"/>
            <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width="20rem"
                         autoComplete={{
                             assocField: "klientSluzba", field: fieldList, filter: Utils.filterCurrentSluzba(), valueForm: <KlientSluzbaForm/>,
                             lazyLoadMaxRows: 15, minLength: minLength, scrollHeight: "50rem"
                         }}/>
            <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
            <XLazyColumn field="miestnost.nazov" header="Miestnosť" width="10rem"/>
            <XLazyColumn field="platba.name" header="Platba" width="8rem" dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.noclaharenPlatba)}/>
        </XLazyDataTable>
    );
}

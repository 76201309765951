import React, {useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XsTableForm} from "./XsTableForm";
import {XsTable} from "../../model/statistics/xs-table.entity";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XsRunStatisticRequest} from "../../common/utils-api";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XRunStatisticDialog, XRunStatisticDialogState} from "./XRunStatisticDialog";
import {Utils, XsAccessType} from "../../Utils";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {XsRowAccessType, SluzbaEnum, EnumEnum} from "../../common/enums";

export const XsTableBrowse = (props: XBrowseProps) => {

    const [xRunStatisticDialogState, setXRunStatisticDialogState] = useState<XRunStatisticDialogState>({opened: false});

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsTableForm/>);
    }

    const onEdit = (selectedRow: any) => {

        if (Utils.xsUserHasAccess(XsAccessType.usageOnly)){
            // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
            props.openForm!(<XsTableForm id={selectedRow.id}/>);
        }else{
            onRunStatistic(selectedRow)
        }
    }

    const onRunStatistic = (selectedRow: XsTable) => {
        // warning for reports without date field
        let hasSubtableWithDateField: boolean = false;
        let hasSubtableWithYearForAgeFromDialog: boolean = false;
        const fieldWithoutDateList: string[] = [];
        for (const xsTableSubtable of selectedRow.xsTableSubtableList) {
            if (xsTableSubtable.xsStatField.dateField === null) {
                fieldWithoutDateList.push(xsTableSubtable.xsStatField.label);
            }
            else {
                hasSubtableWithDateField = true;
            }
            if (xsTableSubtable.xsStatField.yearForAgeFromDialog) {
                hasSubtableWithYearForAgeFromDialog = true;
            }
        }

        // dialog has sense only if there is subtable with date field or subtable with YearForAgeFromDialog (or both)
        if (hasSubtableWithDateField || hasSubtableWithYearForAgeFromDialog) {
            let dateTo: Date | null = null;
            let dateFrom: Date | null = null;
            if (hasSubtableWithDateField) {
                dateTo = XUtilsCommon.today();
                dateFrom = XUtilsCommon.dateAddMonths(dateTo, -1)!;
                dateFrom = XUtilsCommon.dateAddDays(dateFrom, 1)!;
            }
            let yearForAge: Date | null = null;
            if (hasSubtableWithYearForAgeFromDialog) {
                yearForAge = new Date(`${XUtilsCommon.today().getFullYear()}-01-01`);
            }
            setXRunStatisticDialogState({
                opened: true,
                showDateFromDateTo: hasSubtableWithDateField,
                dateFrom: dateFrom,
                dateTo: dateTo,
                showYearForAge: hasSubtableWithYearForAgeFromDialog,
                yearForAge: yearForAge,
                onHideDialog: (ok: boolean, dateFrom: Date | null, dateTo: Date | null, yearForAge: Date | null) => runStatisticOnHideDialog(ok, dateFrom, dateTo, yearForAge, selectedRow, fieldWithoutDateList)
            });
        }
        else {
            // run statistic without dialog date from/to, only with warning
            runStatistic(null, null, null, selectedRow, fieldWithoutDateList);
        }
    }

    const runStatisticOnHideDialog = (ok: boolean, dateFrom: Date | null, dateTo: Date | null, yearForAge: Date | null, xsTable: XsTable, fieldWithoutDateList: string[]) => {
        if (ok) {
            runStatistic(dateFrom, dateTo, yearForAge, xsTable, fieldWithoutDateList);
        }
        setXRunStatisticDialogState({opened: false});
    }

    const runStatistic = (dateFrom: Date | null, dateTo: Date | null, yearForAge: Date | null, xsTable: XsTable, fieldWithoutDateList: string[]) => {
        if (fieldWithoutDateList.length > 0) {
            alert(xLocaleOption('runStatisticMissingDateField', {fieldsWithoutDate: fieldWithoutDateList.join(", ")}))
        }

        const xsRunStatisticRequest: XsRunStatisticRequest = {xsTableId: xsTable.id, dateFrom: dateFrom, dateTo: dateTo, yearForAge: yearForAge};
        // zbavime label diakritiky a medzier, nech ma nazov suboru pekny format
        const fileName: string = XUtils.normalizeString(xsTable.label).trim().replaceAll(' ', '-');
        XUtils.openExcelReport('xs-run-statistic', xsRunStatisticRequest, fileName);
    }

    const canAddRemoveRow = Utils.xsUserHasAccess(XsAccessType.usageOnly);

    let customFilter: XCustomFilter | undefined = undefined;
    // user s pristupom XsAccessType.usageOnly vidi vsetky zaznamy, ostatni (XsAccessType.runOnly) vidia len tie ktore su pre vsetky sluzby alebo maju zapisane konkretne sluzby
    // dovod je, ze ked moze statistiky vytvarat, tak nech moze aj nastavovat aj sluzby pre ktore je statistika urcena (budeme tymto userom doverovat, ze to budu robit korektne)
    // inac by sme museli robit komplikovanu kontrolu, ci user nastavil len svoje sluzby
    if (!canAddRemoveRow) {
        customFilter = {where: `[rowAccessType.code] = '${XsRowAccessType.allUsers}'`
            + ` OR ([rowAccessType.code] = '${XsRowAccessType.specificUsers}'`
                + ` AND EXISTS (SELECT 1 FROM ${Utils.getSchema()}.xs_table_sluzba ts WHERE ts.xs_table_id = [id] AND ts.sluzba_id = ${Utils.getCurrentSluzbaId() ?? 0}))`, params: {}};
    }

    return (
        <div>
            <XLazyDataTable entity="XsTable" label="Statistic tables" rows={30} sortField="label" customFilter={customFilter}
                            onAddRow={canAddRemoveRow ? onAddRow : undefined} onEdit={onEdit} removeRow={canAddRemoveRow}
                            appButtonsForRow={[{key: "button-run-statistic", label: "✓Spusti štatistiku", onClick: onRunStatistic, style: {backgroundColor:'green'}}]}
                            displayed={props.displayed} multilineSwitch={true}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="label" header="Label" width="30rem"/>
                <XLazyColumn field="rowAccessType.name" header="Access type" width="9rem" columnViewStatus={canAddRemoveRow} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.xsRowAccessType)}/>
                <XLazyColumn field="sluzbaList.nazov" header="Služby" width="10rem" columnViewStatus={canAddRemoveRow} dropdownInFilter={true} /*dropdownSortField="aaaaa" neviem nahodit defaultny filter na aktualnu sluzbu. ale ani by to nebolo dobre, lebo viditelne pre vsetky sluzby by to tiez dalo prec*//>
                <XLazyColumn field="xsTableSubtableList.xsStatField.label" header="Statistic fields" width="20rem"/>          
                <XLazyColumn field="xsTableSubtableList.xsStatField.entity" header="Entity" width="15rem" columnViewStatus={Utils.xsUserHasAccess(XsAccessType.usageOnly)}/>
                <XLazyColumn field="xsTableSubtableList.xsStatField.dateField" header="Date field" width="15rem" columnViewStatus={Utils.xsUserHasAccess(XsAccessType.usageOnly)}/>
                <XLazyColumn field="xsTableSubtableList.xsStatField.fieldType.name" header="Field type" width="15rem" columnViewStatus={Utils.xsUserHasAccess(XsAccessType.usageOnly)}/>
            </XLazyDataTable>
            <XRunStatisticDialog dialogState={xRunStatisticDialogState}/>
        </div>
    );
}

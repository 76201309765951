import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {MiestoMoznehoKontaktuForm} from "./MiestoMoznehoKontaktuForm";
import {Utils} from "../../Utils";

export const MiestoMoznehoKontaktuBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<MiestoMoznehoKontaktuForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<MiestoMoznehoKontaktuForm id={selectedRow.id}/>);
    }

    return (
        <XLazyDataTable entity="MiestoMoznehoKontaktu" label="Miesta možného kontaktu" sortField="id desc" rows={30} customFilter={Utils.filterCurrentSluzba()}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true} multilineSwitch={true}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID" columnViewStatus={Utils.isUserAdmin()}/>
            <XLazyColumn field="mestskaCast.name" header="Mestská časť" width="10rem"/>
            <XLazyColumn field="miesto" header="Miesto" width="20rem"/>
            <XLazyColumn field="miestoMoznehoKontaktuKlientSluzbaList.klientSluzba.klient.menoPriezviskoPrezyvka" header="Klienti" width="15rem"/>
            <XLazyColumn field="datumPoslNavstivenia" header="Dátum posl. navštívenia"/>
            <XLazyColumn field="poznamka" header="Poznámka" width="30rem" contentType="multiline"/>
        </XLazyDataTable>
    );
}

import React from "react";
import {Divider} from "primereact/divider";

export const XFormDivider = (props: {
    label?: string;
}) => {
    return (
        <Divider>
            {props.label ? <span style={{fontWeight: 'bold'}}>{props.label}</span> : undefined}
        </Divider>
    );
}

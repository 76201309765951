import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {Utils} from "../../Utils";
import {SluzbaEnum} from "../../common/enums";
import {ZapisPanel} from "./ZapisPanel";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {Zapis} from "../../model/zapisy/zapis.entity";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {ZapisBaseForm} from "./ZapisBaseForm";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {dateAsUI, dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {VykazStreetworkHlavickaForm} from "./VykazStreetworkHlavickaForm";

@Form("Zapis")
export class ZapisForm extends ZapisBaseForm {

    private poznamkaOsetrovnaFromEditStart: string | null = null;

    // pouzivane v XAutoComplete pre asociaciu vykazStreetwork
    vykazStreetworkList: VykazStreetwork[] = [];

    constructor(props: XFormProps) {
        super(props);

        // najoinujeme vykazStreetwork aj s usermi - potrebujeme ich pri predplnani psych a spec userov, staci pre streetwork
        if (Utils.getCurrentSluzba()?.kod === SluzbaEnum.streetwork) {
            this.addField("vykazStreetwork.vykazXUserList.xUser.id");
        }

        // najoinujeme tieto asociacie (lebo cely ZapisPanel je odprogramovany rucne)
        // najoinujeme zaznam KlientSluzba, Klient a aj pohlavie, obec a stat - atributy na tychto zaznamoch su zobrazene v autocomplete na vyber klienta
        this.addField("klientSluzba.klient.pohlavie.name");
        this.addField("klientSluzba.klient.obec.nazov");
        this.addField("klientSluzba.klient.stat.nazov");
        // najoinujeme aj klientSluzba.klientSluzbaZakazList - pouziva sa na vycervenenie klienta v autocomplete
        this.addField("klientSluzba.klientSluzbaZakazList.id");
        this.addField("klientSluzba.statusVSluzbe.code"); // pouziva sa na vysedenie klienta v autocomplete
        this.addField("sluzba.kod");
        if (Utils.isSluzbaLujzaPoradenstvo()) {
            this.addField("klientSluzba.lujzaPoradProjekt.name"); // pre autocomplete
            this.addField("lujzaPoradProjekt.name");
        }
        else if (Utils.isSluzbaVincentPoradenstvo()) {
            this.addField("klientSluzba.vincentPoradProjekt.name"); // pre autocomplete
            this.addField("vincentPoradProjekt.name");
        }
        this.addField("psychPoradXUser.name");
        this.addField("specPoradXUser.name");
        this.addField("specPoradXUser2.name");

        this.onChangeDatum = this.onChangeDatum.bind(this);
        this.onChangeKlientSluzba = this.onChangeKlientSluzba.bind(this);
    }

    createNewObject(): Zapis {
        return {
            id: undefined!,
            sluzba: Utils.getCurrentSluzba()!,
            vykazStreetwork: null,
            klientSluzba: null,
            datum: XUtilsCommon.today(),
            vseobecnyZapis: null,
            atributy: {},
            psychPoradCas: null,
            psychPoradXUser: null,
            specPoradCas: null,
            specPoradXUser: null,
            specPoradXUser2: null,
            specPoradZapis: null,
            lujzaPoradProjekt: null,
            vincentPoradProjekt: null,
            modifDate: null,
            modifXUser: null,
            version: 0
        };
    }

    preInitForm(object: Zapis, operationType: OperationType.Insert | OperationType.Update) {
        this.saveKlientSluzbaFields(object.klientSluzba);
        if (Utils.isSluzbaStreetwork()) {
            this.loadVykazStreetworkList(object); // this.vykazStreetworkList a object.vykazStreetwork su zavisle od object.datum
        }
    }

    async componentDidMount() {
        await super.componentDidMount();

        // metoda vytvorAtributyHistoria() potrebuje mat k dispozicii vytvorene vsetky komponenty typu XFieldSetBase
        // a tie su vytvorene az ked sa zavola callback v setState (mozno sa da zavolat aj niekedy skor ale takto to funguje, napr. v preInitForm to nefunguje)
        // (tiez prava musia byt nacitane)
        this.setStateXForm(() => this.vytvorAtributyHistoria()); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    async loadVykazStreetworkList(zapis: Zapis) {
        // nacita povolene zaznamy VykazStreetwork (s aktualnym datumom) a kontroluje/nastavuje atribut vykazStreetwork (hlavicka zapisu)
        // ak je aktualny atribut vykazStreetwork z nacitaneho zoznamu VykazStreetwork, tak ho ponechame,
        // ak nie je zo zoznamu (zmenil sa datum a nacitali sa ine zaznamy VykazStreetwork), tak ho nastavime na null alebo ho zmenime na zaznam zo zoznamu, ak ma zoznam presne 1 zaznam
        const datumFilter: Date = dateFromModel(zapis.datum) ?? new Date('1000-01-01'); // ak datum nie je vyplneny, tak neponukneme ziadny zaznam VykazStreetwork (najskor musi vyplnit datum)
        const vykazStreetworkList: VykazStreetwork[] = await XUtils.fetchRows('VykazStreetwork', {where: `[datum] = :datum`, params: {datum: datumFilter}}, "tim.enumOrder", ["tim.name", "vykazXUserList.xUser.name"]);
        if (zapis.vykazStreetwork) {
            if (!XUtilsCommon.arrayIncludes(vykazStreetworkList, zapis.vykazStreetwork, 'id')) {
                // zadana hlavicka nie je medzi povolenymi
                if (vykazStreetworkList.length === 1) {
                    zapis.vykazStreetwork = vykazStreetworkList[0];
                }
                else {
                    zapis.vykazStreetwork = null;
                }
            }
        }
        else {
            // hlavicka nie je zadana - predplnime ak sa da
            if (vykazStreetworkList.length === 1) {
                zapis.vykazStreetwork = vykazStreetworkList[0];
            }
        }
        this.vykazStreetworkList = vykazStreetworkList; // pouzijeme v XAutoComplete
    }

    private saveKlientSluzbaFields(klientSluzba: KlientSluzba | null) {
        // ulozime si hodnoty fieldov z entity KlientSluzba zo zaciatku editacie - ak user zmeni hodnotu, zapiseme si modifDate a modifXUser do zaznamu klient
        this.poznamkaOsetrovnaFromEditStart = klientSluzba?.poznamkaOsetrovna ?? null;
    }

    async vytvorAtributyHistoria() {
        const zapis: Zapis | null = this.getZapis();
        if (!zapis) {
            throw "Unexpected error - zapis is null";
        }
        await ZapisPanel.vytvorAtributyHistoria(zapis);

        this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    // pomocna metodka
    private getZapis(): Zapis | null {
        return this.state.object;
    }

    async onChangeDatum(e: XFieldChangeEvent<Zapis>) {
        // zmenil sa datum, skontrolujeme/nastavime atribut vykazStreetwork
        if (Utils.isSluzbaStreetwork()) {
            await this.loadVykazStreetworkList(e.object);
        }
        // zmenil sa datum, prepocitame historiu atributov
        await ZapisPanel.vytvorAtributyHistoria(e.object);
        // kedze sme pouzili await, tak musime zavolat setState (framework necaka a vola setState hned)
        this.setStateXForm();
    }

    onChangeKlientSluzba(klientSluzba: KlientSluzba | null) {
        this.saveKlientSluzbaFields(klientSluzba);
    }

    // overrides method in XFormBase
    async validate(zapis: Zapis): Promise<XErrors> {
        const errors: XErrors = ZapisPanel.validateZapis(zapis.vykazStreetwork, zapis);
        // ak vytvorime hlavicku cez plus button, moze vzniknut rozdielny datum
        if (zapis.vykazStreetwork) {
            if (zapis.datum) {
                if (!XUtilsCommon.dateEquals(dateFromModel(zapis.datum), dateFromModel(zapis.vykazStreetwork.datum))) {
                    errors.vykazStreetwork = `Dátum denníku (${dateAsUI(dateFromModel(zapis.vykazStreetwork.datum))}) musí byť rovnaký ako dátum zápisu (${dateAsUI(dateFromModel(zapis.datum))}).`;
                }
            }
        }
        return errors;
    }

    preSave(object: Zapis) {
        super.preSave(object);

        object.atributyHistoria = undefined; // info o historii atributov nechceme posielat na backend
        object.xFieldSetBaseRef = undefined; // referenciu xFieldSetBaseRef nechceme posielat na backend
    }

    // overridneme standardny saveRow
    async saveRow(): Promise<void> {
        // pouzivame custom service lebo potrebujeme zapisat aj zaznam klientSluzba

        const zapis: Zapis = this.getZapis()!;

        // ak sa zmenil nejaky atribut z entity klientSluzba, tak zapiseme aj modif atributy (viac-menej kozmeticka zalezitost)
        if (zapis.klientSluzba && zapis.klientSluzba.poznamkaOsetrovna !== this.poznamkaOsetrovnaFromEditStart) {
            zapis.klientSluzba.modifDate = new Date();
            zapis.klientSluzba.modifXUser = XUtils.getXToken()?.xUser;
        }

        await XUtils.post('save-zapis', zapis);
    }

    renderDesktop() {
        return (
            <div>
                <XFormScrollable form={this} headerFooterHeight="8rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <div className="x-form-row justify-content-between">
                                <div className="x-form-inline-row">
                                    <XInputDate form={this} field="datum" label="Dátum" labelStyle={{width:'6rem'}} onChange={this.onChangeDatum}/>
                                    {Utils.isSluzbaStreetwork() ?
                                        <XAutoComplete form={this} assocField="vykazStreetwork" label="Denník" labelStyle={{width:'5.5rem'}} width="27rem"
                                                       suggestions={this.vykazStreetworkList} displayField={["tim.name", "vykazXUserList.xUser.name"]}
                                                       assocForm={<VykazStreetworkHlavickaForm/>}/> : null}
                                </div>
                                <XFormHeader form={this} label={Utils.isSluzbaNoclaharen() ? "Ošetrenie" : "Zápis"} style={{marginTop: '0rem', marginBottom: '1rem'}}/>
                                <div className="x-form-inline-row">
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." labelStyle={{width:'9rem'}} readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" labelStyle={{width:'9rem'}} inputStyle={{width:'12.35rem'}} readOnly={true}/>
                                    <XInputDecimal form={this} field="id" label="ID" labelStyle={{width:'3rem'}} readOnly={true}/>
                                </div>
                            </div>
                            <ZapisPanel zapisForm={true} vykazStreetwork={this.state.object?.vykazStreetwork}
                                        zapis={this.state.object} onChangeZapis={this.onChangeForm}
                                        onChangeKlientSluzba={this.onChangeKlientSluzba}
                                        userSpecPoradList={this.userSpecPoradList}
                                        userPsychPoradList={this.userPsychPoradList}
                                        idSluzbaRolaPravaMap={this.state.idSluzbaRolaPravaMap}
                                        kodSluzbaRolaPravaMap={this.state.kodSluzbaRolaPravaMap}
                                        onEditFieldChange={this.onEditFieldChange}/>
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    // TODO - bolo by fajn pridat label + tlacitko na zobrazenie modif atributov (vedla datumu)
    renderMobile() {
        return (
            <div>
                <XFormScrollable form={this} headerFooterHeight="8rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <XInputDate form={this} field="datum" label="Dátum" labelStyle={{width:'6rem'}} onChange={this.onChangeDatum}/>
                            {Utils.isSluzbaStreetwork() ?
                                <XAutoComplete form={this} assocField="vykazStreetwork" label="Denník" labelStyle={{width:'5rem'}} width="calc(100% - 5.5rem)"
                                               suggestions={this.vykazStreetworkList} displayField={["tim.name", "vykazXUserList.xUser.name"]}
                                               assocForm={<VykazStreetworkHlavickaForm/>}/> : null}
                            <ZapisPanel zapisForm={true} vykazStreetwork={this.state.object?.vykazStreetwork}
                                        zapis={this.state.object} onChangeZapis={this.onChangeForm}
                                        onChangeKlientSluzba={this.onChangeKlientSluzba}
                                        userSpecPoradList={this.userSpecPoradList}
                                        userPsychPoradList={this.userPsychPoradList}
                                        idSluzbaRolaPravaMap={this.state.idSluzbaRolaPravaMap}
                                        kodSluzbaRolaPravaMap={this.state.kodSluzbaRolaPravaMap}
                                        onEditFieldChange={this.onEditFieldChange}/>
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    render() {
        if (!XUtils.isMobile()) {
            return this.renderDesktop();
        } else {
            return this.renderMobile();
        }
    }
}

import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {NoclaharenPlatbaEnum, Param, Rola, SluzbaEnum} from "../../common/enums";
import {Utils} from "../../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {Klient} from "../../model/klient/klient.entity";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {Miestnost} from "../../model/noclaharen/miestnost.entity";
import {Ubytovanie} from "../../model/noclaharen/ubytovanie.entity";
import {DataTable, DataTableSelectionSingleChangeEvent} from "primereact/datatable";
import {Column} from "primereact/column";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {InputNumber} from "primereact/inputnumber";
import {XFormInlineRow} from "@michalrakus/x-react-web-lib/XFormInlineRow";
import {FindMiestnostiAInfoResponse, GetCisloVrecaResponse, TlacZmluvyRequest} from "../../common/noclaharen-api";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XEnum} from "../../model/user/x-enum.entity";
import {dateAsUI, dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {Button} from "primereact/button";
import {dateFormat, XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {KlientSluzbaAutoComplete} from "../klient/KlientSluzbaAutoComplete";

@Form("Ubytovanie")
export class UbytovanieForm extends XFormBaseModif {

    private todayLogicky: Date = Utils.todayNoclaharen();

    private miestnostList: Miestnost[] = [];
    private platbaSuggestions: XEnum[] = [];

    private poznamkaFromEditStart: string | null = null;
    private uzivatelFromEditStart: boolean | null = null;

    private platbaFromEditStart: XEnum | null = null;

    private klientError: string | undefined;

    private xInputTextareaRef: any;

    constructor(props: XFormProps) {
        super(props);

        this.xInputTextareaRef = React.createRef();

        // najoinujeme aj klient.pohlavie, klient.obec a klient.stat - su zobrazene v autocomplete na vyber klienta
        this.addField("klientSluzba.klient.pohlavie.name");
        this.addField("klientSluzba.klient.obec.nazov");
        this.addField("klientSluzba.klient.stat.nazov");
        // najoinujeme aj klientSluzba.klientSluzbaZakazList - pouziva sa na vycervenenie klienta
        this.addField("klientSluzba.klientSluzbaZakazList.id");
        // najoinujeme aj klientSluzba.statusVSluzbe - pouziva sa na vysedenie/vyhnedenie klienta
        this.addField("klientSluzba.statusVSluzbe.code");

        // najoinujeme aj miestnost (ma rucne odprogramovany input - tabulku)
        this.addField("miestnost.nazov");

        this.onSelectionChangeMiestnost = this.onSelectionChangeMiestnost.bind(this);
        this.onChangeKlientSluzba = this.onChangeKlientSluzba.bind(this);
        this.onChangeDatum = this.onChangeDatum.bind(this);
        this.onChangeUzivatel = this.onChangeUzivatel.bind(this);
        this.onClickTlacZmluvy = this.onClickTlacZmluvy.bind(this);
    }

    // pomocna metodka
    private getUbytovanie(): Ubytovanie | null {
        return this.state.object;
    }

    createNewObject(): XObject {
        return {datum: this.todayLogicky, klientSluzba: null, miestnost: null, platba: null, version: 0};
    }

    preInitForm(object: Ubytovanie, operationType: OperationType.Insert | OperationType.Update) {
        this.saveKlientSluzbaFields(object.klientSluzba);
        this.loadDataPreKlientSluzba(object);

        // ulozime si hodnotu zo zaciatku editacie (pripadna zmena klienta nas nezaujima)
        this.platbaFromEditStart = object.platba;

        // nacitali sme zaznam ubytovanie, mame datum, mozme nacitat miestnosti a dalsie info zavisle od datumu
        // (preInitForm je pokracovanie componentDidMount() a tento kod sa vola pri inserte aj update-e)
        this.loadDataPreDatum(object.datum, object.klientSluzba?.uzivatel);
    }

    private saveKlientSluzbaFields(klientSluzba: KlientSluzba | null) {
        // ulozime si hodnoty fieldov z entity klient zo zaciatku editacie - ak user zmeni hodnotu, zapiseme si modifDate a modifXUser do zaznamu klient
        this.poznamkaFromEditStart = klientSluzba?.poznamka ?? null;
        this.uzivatelFromEditStart = klientSluzba?.uzivatel ?? null;
    }

    formReadOnly(object: Ubytovanie, field: string): boolean {
        return !UbytovanieForm.mozeEditovatVymazat(object);
    }

    static mozeEditovatVymazat(ubytovanie: Ubytovanie): boolean {
        // ak je veduci (alebo admin), moze editovat vzdy
        if (Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci)) {
            return true;
        }

        // ak je pracovnik alebo socialny pracovnik, moze editovat len dnesne zaznamy
        if (ubytovanie) {
            const datum: Date | null = dateFromModel(ubytovanie.datum);
            if (XUtilsCommon.dateEquals(datum, Utils.todayNoclaharen())) {
                return true;
            }
        }
        return false;
    }

    onSelectionChangeMiestnost(event: DataTableSelectionSingleChangeEvent<Miestnost[]>) {
        // zmenu miestnosti zapiseme do modelu len ak mozme editovat (readOnly mod pre tabulku miestnosti)
        if (UbytovanieForm.mozeEditovatVymazat(this.state.object)) {
            this.state.object.miestnost = event.value;
            this.setStateXForm();
        }
    }

    readOnlyKlientSluzba(): boolean {
        // len pre insert dovolime zadavat klienta, dovod je ze pri zmene klienta by sme museli komplikovane riesit zmenu predplatenych noci (jednemu klientovi pridat druhemu ubrat)
        return !this.isAddRow();
    }

    async onChangeKlientSluzba(klientSluzba: KlientSluzba | null, objectChange: OperationType) {

        const ubytovanie: Ubytovanie = this.getUbytovanie()!;
        ubytovanie.klientSluzba = klientSluzba!;

        this.saveKlientSluzbaFields(klientSluzba);
        this.loadDataPreKlientSluzba(ubytovanie);

        // zmenil sa klient, mohol sa zmenit priznak uzivatel - zmenime aj miestnostList ak treba
        this.setMiestnostList(this.miestnostList, klientSluzba?.uzivatel);

        // ak ma predplatene noci, tak mu predplnime platbu na predplatene
        if (klientSluzba) {
            if (klientSluzba.predplateneNoci && klientSluzba.predplateneNoci > 0) {
                ubytovanie.platba = NoclaharenPlatbaEnum.Predplatene;
            }
        }

        // ak nema vyplnene cislo vreca, tak mu priradime cislo vreca zo sequence
        if (klientSluzba && (klientSluzba.cisloVreca === null || klientSluzba.cisloVreca === undefined)) {
            klientSluzba.cisloVreca = await this.getCisloVreca();
            // zmenil sa stav objektu - kedze bol volany await, automaticky volany setState uz bol volany
            // ked este nebolo cislo vreca vyplnene -> musime zavolat setState znova
            this.setState({object: this.state.object});
        }
        // pomenili sme this.state.object, zavolame setState
        // po zbehnuti zmeny stavu este (manualne) zavolame metodu autoResize komponentu XInputTextarea - metoda nastavi spravnu vysku textarea (podla poctu riadkov)
        this.setStateXForm(() => this.xInputTextareaRef.current.autoResize());

        // specialna poziadavka - ak ma klient narodeniny, dame info
        if (klientSluzba && !klientSluzba.klient.datumNarodeniaIbaRok) {
            const datumNarodenia: Date | null = dateFromModel(klientSluzba.klient.datumNarodenia);
            if (datumNarodenia) {
                const datumNarodeniaMonth: number = datumNarodenia.getMonth();
                const datumNarodeniaDay: number = datumNarodenia.getDate();
                const todayNoclaharen: Date = Utils.todayNoclaharen();
                const todayNoclaharenMonth: number = todayNoclaharen.getMonth();
                const todayNoclaharenDay: number = todayNoclaharen.getDate();
                if (datumNarodeniaMonth === todayNoclaharenMonth && datumNarodeniaDay === todayNoclaharenDay) {
                    alert(`Upozornenie: klient má dnes narodeniny: ${XUtilsCommon.dateDiffInYears(datumNarodenia, todayNoclaharen)} rokov`);
                }
            }
        }
    }

    async getCisloVreca(): Promise<number | null> {
        let cisloVreca: number | null;
        const getCisloVrecaResponse: GetCisloVrecaResponse = await XUtils.fetch('get-cislo-vreca', {});
        if (getCisloVrecaResponse.cisloVreca === undefined) {
            alert(getCisloVrecaResponse.chyba);
            cisloVreca = null;
        }
        else {
            cisloVreca = getCisloVrecaResponse.cisloVreca;
        }
        return cisloVreca;
    }

    onChangeDatum(e: XFieldChangeEvent<Ubytovanie>) {
        // rereadneme atributy zavisle od datumu
        this.loadDataPreDatum(e.object.datum, e.object.klientSluzba?.uzivatel);
    }

    onChangeUzivatel(e: XFieldChangeEvent<Ubytovanie>) {
        if (e.object.klientSluzba) {
            if (e.object.klientSluzba.uzivatel) {
                e.object.klientSluzba.testovany = Utils.todayNoclaharen();
            }
            else {
                e.object.klientSluzba.testovany = null;
            }
        }
        // zmenime aj miestnostList ak treba
        this.setMiestnostList(this.miestnostList, e.object.klientSluzba?.uzivatel);
    }

    loadDataPreKlientSluzba(ubytovanie: Ubytovanie) {
        const platbaSuggestions: XEnum[] = [NoclaharenPlatbaEnum.Zaplatil, NoclaharenPlatbaEnum.Nezaplatil];
        const klientSluzba: KlientSluzba | null = ubytovanie.klientSluzba;
        if (klientSluzba) {
            // ak ma predplateneNoci (aspon jednu) tak pridame aj moznost Predplatene
            if (klientSluzba.predplateneNoci && klientSluzba.predplateneNoci > 0) {
                platbaSuggestions.push(NoclaharenPlatbaEnum.Predplatene);
            }
            else {
                // nema predplatene
                if (ubytovanie.platba?.id === NoclaharenPlatbaEnum.Predplatene.id) {
                    // ma vybratu platbu Predplatene
                    if (this.isAddRow()) {
                        // sme v inserte, zrusime mu volbu Predplatene
                        ubytovanie.platba = null!;
                    }
                    else {
                        // sme v update, ponechame mu moznost Predplatene (uz bolo odratane z klientSluzba.predplateneNoci)
                        platbaSuggestions.push(NoclaharenPlatbaEnum.Predplatene);
                    }
                }
            }
        }
        // platbaSuggestions zapiseme do state, aby sa prejavila zmena
        this.setState({platbaSuggestions: platbaSuggestions});
    }

    async loadDataPreDatum(datum: Date | null, uzivatel: boolean) {
        const miestnostiAInfo: FindMiestnostiAInfoResponse = await XUtils.fetchOne('find-miestnosti-a-info', {datum: datum});
        // ulozime si zoznam miestnostList, nech pri zmene klienta nemusime robit reread (optimalizacia)
        this.miestnostList = miestnostiAInfo.miestnostList;

        this.setMiestnostList(this.miestnostList, uzivatel);
        this.setState({sumaUbytovanych: miestnostiAInfo.sumaUbytovanych, zaplatili: miestnostiAInfo.zaplatili});
    }

    setMiestnostList(miestnostList: Miestnost[], uzivatel: boolean | undefined) {
        if (uzivatel) {
            // miestnosti pre uzivatela vyfiltrujeme len na zaznamoch pre dnesny den
            // pri zaznamoch v minulosti totiz klient nemusel mat zaskrtnuty priznak uzivatel a kludne mohol spat aj v A-ckovych miestnostiach
            const ubytovanie: Ubytovanie = this.getUbytovanie()!;
            const datum: Date | null = dateFromModel(ubytovanie.datum);
            if (XUtilsCommon.dateEquals(datum, Utils.todayNoclaharen())) {
                miestnostList = miestnostList.filter((value: Miestnost) => value.nazov.startsWith("B_"));

                // ak mame vybratu miestnost ktora "zmizla" tak ju nastavime na null
                if (ubytovanie.miestnost && !miestnostList.some((value: Miestnost) => value.id === ubytovanie.miestnost.id)) {
                    ubytovanie.miestnost = null!;
                    // zmenil sa stav objektu
                    this.setState({object: this.state.object});
                }
            }
        }
        // miestnostList musime zapisat do state, inac sa zmena neprejavi
        this.setState({miestnostList: miestnostList});
    }

    readOnlyKlientField(): boolean {
        return !(this.getUbytovanie()?.klientSluzba);
    }

    templateVolneMiesta(rowData: Miestnost): React.ReactNode {
        const elemList: JSX.Element[] = [<span key="pocet-volnych">{rowData.pocetVolnychPosteli}</span>];
        if (rowData.pocetVolnychPosteli !== undefined && rowData.pocetVolnychPosteli < rowData.pocetPosteli) {
            elemList.push(<span key="pocet-posteli" className="text-gray-400">{" z " + rowData.pocetPosteli}</span>);
        }
        return elemList;
    }

    async onClickTlacZmluvy() {

        const ubytovanie: Ubytovanie = this.getUbytovanie()!;

        if (!ubytovanie.klientSluzba) {
            alert("Zadajte klienta.");
            return;
        }

        const datumZmluvy: Date = Utils.todayNoclaharen();
        const cisloZmluvy: string = dateFormat(new Date(), 'mmddHHMMss/yyyy'); // datumZmluvy nema hodiny/minuty/sekundy
        const tlacZmluvyRequest: TlacZmluvyRequest = {klient: ubytovanie.klientSluzba.klient, datumZmluvy: datumZmluvy, cisloZmluvy: cisloZmluvy};
        // zapiseme si datum do fieldu zmluvaZmenena (uzivatel neskor pri save zapise zmluvaZmenena do DB - ak nahodou zrusi operaciu, bude musiet znova tlacit)
        ubytovanie.klientSluzba.zmluvaZmenena = datumZmluvy;
        // aby sa prejavila zmena
        this.setState({object: this.state.object});

        let response;
        try {
            response = await XUtils.fetchBasicJson('tlac-zmluvy', tlacZmluvyRequest);
        }
        catch (e) {
            XUtils.showErrorMessage("Nepodarilo sa vytvoriť/stiahnuť súbor zmluvy.", e);
            return;
        }

        const fileName = `Zmluva-${cisloZmluvy.replaceAll('/', '-')}.docx`;
        // let respJson = await response.json(); - konvertuje do json objektu
        let respBlob = await response.blob();

        // download blob-u (download by mal fungovat asynchronne a "stream-ovo" v spolupraci so servrom)
        let url = window.URL.createObjectURL(respBlob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
    }

    async validate(object: Ubytovanie): Promise<XErrors> {
        const errors: XErrors = {};
        if (this.klientError) {
            errors.klient = this.klientError;
        }
        else if (!object.klientSluzba) {
            errors.klientSluzba = "Klient musí byť vyplnený.";
        }
        if (object.miestnost === null) {
            errors.miestnost = "Vyselektujte miestnosť.";
        }
        // len pri inserte testujeme cislo vreca a datum zmluvy
        if (this.isAddRow()) {
            if (object.klientSluzba) {
                if (object.klientSluzba.cisloVreca === null) {
                    errors["klientSluzba.cisloVreca"] = "Nepodarilo sa nájsť voľné číslo vreca.";
                }
                const zmluvaZmenena: Date | null = dateFromModel(object.klientSluzba.zmluvaZmenena);
                if (zmluvaZmenena === null) {
                    errors["klientSluzba.zmluvaZmenena"] = "Klient nemá zapísaný dátum zmluvy. Vytlačte zmluvu stlačením tlačítka Tlač zmluvy.";
                }
                else {
                    const zmluvyPlatneOd: Date = await Utils.getXParamValueAsDate(Param.n_zmluvyPlatneOd);
                    if (zmluvaZmenena < zmluvyPlatneOd) {
                        errors["klientSluzba.zmluvaZmenena"] = `Dátum zmluvy nesmie byť starší ako ${dateAsUI(zmluvyPlatneOd)}. Vytlačte novú zmluvu stlačením tlačítka Tlač zmluvy.`;
                    }
                }

                // nedovolime vytvorit ubytovanie, ak ma klient zakaz alebo je zosnuly, pripadne neaktivny
                if (Utils.klientSluzbaMaZakaz(object.klientSluzba, this.todayLogicky)) {
                    errors.klientSluzba = "Klient má zaevidované prerušenie služby.";
                }
                else if (Utils.klientSluzbaZosnuly(object.klientSluzba)) {
                    errors.klientSluzba = 'Klient má status "zosnulý".';
                }
                else if (Utils.klientSluzbaNeaktivnyOrNieJeKlient(object.klientSluzba)) {
                    errors.klientSluzba = `Klient má status "${object.klientSluzba.statusVSluzbe?.name}".`;
                }
            }
        }
        return errors;
    }

    // overridneme standardny saveRow
    async saveRow(): Promise<void> {
        // pouzivame custom service lebo potrebujeme zapisat aj klienta

        const ubytovanie: Ubytovanie = this.getUbytovanie()!;

        // ak sa zmenil nejaky atribut z entity klientSluzba, tak zapiseme aj modif atributy (viac-menej kozmeticka zalezitost)
        if (ubytovanie.klientSluzba.poznamka !== this.poznamkaFromEditStart || ubytovanie.klientSluzba.uzivatel !== this.uzivatelFromEditStart) {
            ubytovanie.klientSluzba.modifDate = new Date();
            ubytovanie.klientSluzba.modifXUser = XUtils.getXToken()?.xUser;
        }

        // ak sa zmenila platba na predplatene (alebo naopak) tak znizime pocet predplatenych noci na klientovi (modif atributy nemenime)
        // nemenime priamo atribut predplateneNoci lebo ak save neprejde (napr. zahuci na unique constrainte) tak chceme mat v atribute predplateneNoci povodnu hodnotu
        ubytovanie.klientSluzba.predplateneNociToSave = undefined; // default
        if (ubytovanie.platba !== this.platbaFromEditStart) {
            if (ubytovanie.platba?.id === NoclaharenPlatbaEnum.Predplatene.id) {
                // zmena na Preplatene (this.platbaFromEditStart !== Preplatene)
                ubytovanie.klientSluzba.predplateneNociToSave = ubytovanie.klientSluzba.predplateneNoci! - 1;
            }
            else if (this.platbaFromEditStart?.id === NoclaharenPlatbaEnum.Predplatene.id) {
                // zmena z Preplatene na nieco ine (ubytovanie.platba !== Preplatene)
                ubytovanie.klientSluzba.predplateneNociToSave = ubytovanie.klientSluzba.predplateneNoci! + 1;
            }
        }

        await XUtils.post('save-ubytovanie', ubytovanie);
    }

    createLabel(): string {
        const ubytovanie: Ubytovanie | null = this.getUbytovanie();
        let label: string = Utils.klientCreateLabel("Ubytovanie", ubytovanie?.klientSluzba?.klient);
        if (ubytovanie) {
            if (ubytovanie.datum) {
                label += " " + dateAsUI(dateFromModel(ubytovanie.datum));
            }
        }
        return label;
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()}/>
                <XFormScrollable form={this} headerFooterHeight="11.5rem" widthFitContent={true}>
                    <XFormRow>
                        {/* povodny autocomplete:
                        <XAutoComplete form={this} assocField="klient" label="Klient" filter={Utils.klientFilterNoclaharen()}
                                       displayField={["V:cisloVreca", "meno", "priezvisko", "prezyvka", "RČ:rodneCislo", "pohlavie.name", "datumNarodenia", "ulica", "cisloDomu", "psc", "obecText", "obec.nazov", "stat.nazov", "ID:id", "poznamky"]}
                                       fields={["klientZakazUbytovatList.id"]}
                                       itemTemplate={this.itemTemplateKlient} inputClassName={this.inputClassNameKlient()}
                                       searchBrowse={<KlientNoclaharenBrowse/>} assocForm={<KlientNoclaharenForm/>} labelStyle={{width:'6rem'}} width="63rem" scrollHeight="50rem"
                                       readOnly={this.readOnlyKlient()} onChange={this.onChangeKlient} suggestionsLoad="lazy" lazyLoadMaxRows={15} minLength={2} setFocusOnCreate={true}/>*/}
                        <div className="field grid w-full" style={{paddingRight: '0.5rem'}}>
                            <label htmlFor="klient" className="col-fixed" style={{width:'6rem'}}>Klient *</label>
                            <KlientSluzbaAutoComplete value={this.state.object ? this.state.object.klientSluzba : null} onChange={this.onChangeKlientSluzba}
                                                          error={Utils.getError(this.state.errorMap, "klientSluzba")}
                                                          onErrorChange={(error: (string | undefined)) => this.klientError = error}
                                                          readOnly={this.readOnlyKlientSluzba()} maxWidth="calc(100% - 6rem)" poznamka={true}/>
                        </div>
                    </XFormRow>
                    <XFormRow>
                        <XFormCol>
                            <DataTable value={this.state.miestnostList} dataKey="id" className="p-datatable-sm x-lazy-datatable"
                                       selectionMode="single" selection={this.state.object?.miestnost ?? undefined} onSelectionChange={this.onSelectionChangeMiestnost}>
                                <Column field="nazov" header="Názov miestnosti"/>
                                <Column field="pocetVolnychPosteli" header="Voľné miesta" body={this.templateVolneMiesta}/>
                            </DataTable>
                            <XFormInlineRow>
                                <div className="field grid">
                                    <label htmlFor="sumaUbytovanych" className="col-fixed" style={{width:'7rem'}}>Ubytovaní</label>
                                    <InputNumber id="sumaUbytovanych" value={this.state.sumaUbytovanych} readOnly={true} mode="decimal" locale="de-DE"
                                                 minFractionDigits={0} maxFractionDigits={0} inputStyle={{width:'3rem'}}/>
                                </div>
                                <div className="field grid">
                                    <label htmlFor="zaplatili" className="col-fixed" style={{width:'5.3rem'}}>Zaplatili</label>
                                    <InputNumber id="zaplatili" value={this.state.zaplatili} readOnly={true} mode="decimal" locale="de-DE"
                                                 minFractionDigits={0} maxFractionDigits={0} inputStyle={{width:'3rem'}}/>
                                </div>
                            </XFormInlineRow>
                        </XFormCol>
                        <XFormCol>
                            <XFormRow>
                                <XFormCol labelStyle={{width:'13.5rem'}}>
                                    <XInputDate form={this} field="datum" label="Dátum nocľahu" onChange={this.onChangeDatum} readOnly={!Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci)}/>
                                    <XInputDecimal form={this} field="klientSluzba.cisloVreca" label="Číslo vreca" readOnly={true} inputStyle={{width:'4rem'}}/>
                                    <XInputDecimal form={this} field="klientSluzba.predplateneNoci" label="Predplatené" readOnly={true} inputStyle={{width:'4rem'}}/>
                                    <XAutoComplete form={this} assocField="platba" displayField="name" label="Platba" suggestions={this.state.platbaSuggestions} width="8rem"/>
                                </XFormCol>
                                <XFormCol labelStyle={{width:'9rem'}}>
                                    <XFormInlineRow>
                                        <XCheckbox form={this} field="klientSluzba.uzivatel" label="Užívateľ" labelStyle={{width:'6.5rem'}} readOnly={this.readOnlyKlientField()} onChange={this.onChangeUzivatel}/>
                                        <XInputDate form={this} field="klientSluzba.testovany" label="Testovaný" readOnly={true} labelStyle={{width:'7rem'}}/>
                                    </XFormInlineRow>
                                    <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" inputStyle={{width:'12.35rem'}}/>
                                </XFormCol>
                            </XFormRow>
                                <XFormInlineRow>
                                    <XInputDate form={this} field="klientSluzba.zmluvaZmenena" label="Zmluva nap. zmenená" readOnly={true} labelStyle={{width:'14.25rem'}}/>
                                    <div className="field grid">
                                        <Button icon="pi pi-print" label="Tlač zmluvy" onClick={this.onClickTlacZmluvy} disabled={!UbytovanieForm.mozeEditovatVymazat(this.state.object)}/>
                                    </div>
                                </XFormInlineRow>
                            <XInputTextarea ref={this.xInputTextareaRef} form={this} field="klientSluzba.poznamka" label="Poznámka" labelOnTop cols="full" autoResize={true} readOnly={this.readOnlyKlientField()}/>
                        </XFormCol>
                    </XFormRow>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
